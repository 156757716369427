<!-- Güncelleme kontrolü için ayrı bir ng-container kullanın -->
<ng-container *ngIf="updateCheckService.updateAvailable$ | async as isUpdateAvailable">
  <update-modal *ngIf="isUpdateAvailable" (countdownFinished)="updateCheckService.applyUpdate()"
    [countdownSeconds]="10"></update-modal>
</ng-container>

<!-- router-outlet'i her zaman görünür hale getirin -->
<app-background class="absolute h-screen bg-header left-0 right-0 mx-auto overflow-hidden"></app-background>

<!-- div Class for Toaster middle in the screen -->
<router-outlet [class.hidden]="updateCheckService.updateAvailable$ | async"></router-outlet>

<div
  class="absolute right-0 left-auto max-h-screen overflow-y-auto overflow-x-hidden flex flex-col mt-20 mr-2 transition-all duration-300"
  style="direction: rtl; box-sizing: border-box; padding: 0; z-index:5000;" [class.w-[450px]]="!toastNotify.isMuted"
  [class.w-[80px]]="toastNotify.isMuted">

  <div style="direction: ltr;" class="m-0 p-0" *ngIf="toastNotify.activeNotifications > 0 || toastNotify.isMuted">

    <ng-container *ngIf="!toastNotify.isMuted; else muteTemplate">

      <div class="h-16 m-2 mr-4 frosty flex items-center justify-center space-x-6">

        <!-- Sessize Al Butonu -->
        <div class="relative inline-block">
          <button (click)="toggleMuteNotifications()"
            class="relative text-xs font-medium px-3 py-1.5 frosty flex items-center space-x-1">
            <fa-icon [icon]="toastNotify.isMuted ? ['fas', 'bell-slash'] : ['fas', 'bells']" class="text-sm"></fa-icon>
            <span>{{'SystemAdmin.Notifications.MuteFor1Minute' | translate}}</span>
          </button>
        </div>


        <!-- Bildirimleri Sil Butonu -->
        <div class="relative inline-block" *ngIf="!toastNotify.isMuted">
          <button (click)="clearNotifications()"
            class="relative text-xs font-medium px-3 py-1.5 frosty red flex items-center space-x-1">
            <fa-icon [icon]="['fas', 'trash']" class="text-sm"></fa-icon>
            <span>{{'SystemAdmin.Notifications.ClearNotifications' | translate}}</span>
            <span *ngIf="toastNotify.activeNotifications > 0"
              class="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white text-xs font-semibold h-4 w-4 flex items-center justify-center rounded-full">
              {{ toast.currentlyActive }}
            </span>
          </button>
        </div>

      </div>

    </ng-container>
    <ng-template #muteTemplate>


      <div class="h-16 m-2 mr-4 frosty  flex items-center justify-center space-x-6">

        <div class="relative inline-block">
          <button (click)="toggleMuteNotifications()"
            class="relative text-xs font-medium px-3 py-1 frosty white flex flex-col items-center">
            <!-- Zil Simgesi -->
            <fa-icon [icon]="toastNotify.isMuted ? ['fas', 'bell-slash'] : ['fas', 'bell']" class="text-lg"></fa-icon>

            <!-- Countdown Gösterimi -->
            <span *ngIf="muteCountdown$ | async as countdown" class="text-center mt-1 text-xs">
              <ng-container *ngIf="countdown > 0">{{ countdown }}</ng-container>
            </span>
          </button>
        </div>
      </div>
    </ng-template>
  </div>


  <div style="direction: ltr;" class="m-0 p-0" [hidden]="toastNotify.isMuted" toastContainer>


  </div>
</div>