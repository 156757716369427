import { Guid } from "@app/core/constants/constants";
import { RecordState } from "@app/core/constants/global-enum";
import { RecordBaseDTO } from "@app/shared/classes/record-base";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export namespace CustomReportEditor {

  export class CustomReport extends RecordBaseDTO {
    code: string;
    name: string;
    description?: string;
    colorCode?: string;
    icon?: IconProp;
    iconColorCode?: string;

    private _gridState: string;
    dateRange?: number;
    shareRole: number;
    isPublic: boolean;
    order: number;
    groupKey?: 'Sensor' | 'Location' | 'SensorHistory' | 'LocationHistory';
    new: boolean;

    private _gridStateChanged: boolean = false;
    private resetTimeout: any;

    constructor() {
      super();
      // set defaults
      this.id = Guid.newGuid();
      this.code = '';
      this.name = '';
      this.description = '';
      this.colorCode = '';
      this.icon = ['fas', 'chart-line'];
      this.iconColorCode = '';
      this.gridState = '';
      this.dateRange = 0;
      this.shareRole = 0;
      this.isPublic = false;
      this.order = 0;
      this.groupKey = 'Sensor';
      this.new = false;
      this.plainObject = {};

    }


    get gridState(): string {
      return this._gridState;
    }

    set gridState(value: string) {
      if (this._gridState !== value) {
        this._gridState = value;
        this.setChangedState();
      }
    }

    get gridStateChanged(): boolean {
      return this._gridStateChanged;
    }

    private setChangedState(): void {
      this._gridStateChanged = true;

      // Önceki timeout varsa iptal et
      if (this.resetTimeout) {
        clearTimeout(this.resetTimeout);
      }

      // 3 saniye sonra `changed` durumunu false yap
      this.resetTimeout = setTimeout(() => {
        this._gridStateChanged = false;
      }, 1500);
    }


    public get plainObject() {
      return {
        id: this.id,

        code: this.code,
        name: this.name,
        description: this.description,
        colorCode: this.colorCode,
        icon: this.icon,
        iconColorCode: this.iconColorCode,
        gridState: this.gridState,
        dateRange: this.dateRange,
        shareRole: this.shareRole,
        isPublic: this.isPublic,
        order: this.order,
        groupKey: this.groupKey,

        createdAt: this.createdAt,
        createdById: this.createdById,
        updatedAt: this.updatedAt,
        updatedById: this.updatedById,
        recordState: this.recordState,
      };
    }

    public set plainObject(value: any) {

    }
  }

  export class TabModel {

    constructor(public label: string,
      public icon: IconProp,
      public doneIcon: IconProp,
      public failIcon: IconProp,
      public status: 'default' | 'done' | 'fail') { }
  }

  export interface OperationResult {
    success: boolean;
    action: 'create' | 'update' | 'delete' | 'discard' | 'refresh';
    report?: CustomReportEditor.CustomReport;
    error?: any;
  }

}


