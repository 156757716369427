import { Injectable } from '@angular/core';
import { ValueType, ValueTypes } from '@app/core/constants/global-enum';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { LiveDTO } from '../dtos/live';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    constructor() { }


    public resolveIcon(iconData: any, fallback: string = '["fal","question"]'): IconProp {

        let icon: IconProp;
        try {
            if (iconData) {
                // Eğer iconData zaten bir nesneyse, doğrudan döndür
                if (typeof iconData === 'object') {
                    return iconData;
                }

                // Eğer string ise, JSON olarak parse et
                const iconConfig = `${iconData}`.replace(/'/g, '"');

                if (iconConfig) {
                    icon = JSON.parse(iconConfig);
                    return icon;
                }
            }
        }
        catch { }

        return JSON.parse(fallback.replace(/'/g, '"'));
    }

    public flatIcon(iconData: any): string | undefined {
        const ip = this.resolveIcon(iconData);
        if (ip) {
            return `${ip[0]}:${ip[1]}`;
        } else {
            return undefined;
        }
    }

    public resolveValue<T>(val: any, valueType: ValueTypes): T {
        switch (+valueType) {
            case ValueTypes.Number:
            case ValueTypes.Currency:
                return (+val) as unknown as T; // cast number
            case ValueTypes.Boolean:
                return (val.toLowerCase() === 'true') as unknown as T; //cast boolean
            case ValueTypes.DateTime:
                return new Date(val) as unknown as T; //cast date
            case ValueTypes.String:
            case ValueTypes.Password:
            case ValueTypes.Color:
            case ValueTypes.PhotoUrl:
            case ValueTypes.Link:
            default:
                return val as unknown as T; // vat ken ay du sam tayms

        }
    }

}
