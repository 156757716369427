import { Guid } from '@app/core/constants/constants';
import { RecordState } from '@app/core/constants/global-enum';

export class RecordBaseDTO {
    id: Guid;

    createdAt: Date;
    createdByName: string;
    createdById: Guid;

    updatedAt: Date;
    updatedByName: string;
    updatedById: Guid;

    recordState: RecordState;
}

export class RecordBaseModel {
    id: Guid;
}
