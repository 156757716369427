

<div 
  class="absolute left-0 right-0 mx-auto h-screen w-screen flex flex-col justify-center items-center  overscroll-none backdrop-br bg-black/80" 
  >

  <div class="max-w-sm overflow-hidden shadow-lg frosty m-4  text-center px-4 md:px-0 ">

    <div class="px-6 py-4">
      <div class="font-bold text-xl mb-2">{{'SystemAdmin.Components.UpdateModal.Title' | translate}}</div>
      
      <p class="text-gray-700 text-base">
        {{'SystemAdmin.Components.UpdateModal.Description' | translate}}
      </p>

      <div class="text-center">
        <!-- Geri sayım gösterimi veya güncelleme mesajı -->
        <span *ngIf="currentCountdown > 0; else updateStart" class="text-8xl font-bold text-red-500 ">
          {{ currentCountdown }}
        </span>

        <!-- Güncelleme başladığında gösterilecek içerik -->
        <ng-template #updateStart>

          <span class="text-lg font-bold text-red-500 mt-6">
            {{'SystemAdmin.Components.UpdateModal.UpdateStarting' | translate}}
          </span>

        </ng-template>
      </div>

    </div>

  </div>

</div>
<div class="fireworks-container"></div>