export interface BackgroundStyle {
  backgroundImage: string;
  minParticleSize: number; // vmin biriminde
  maxParticleSize: number;
  minScale: number;
  maxScale: number;
  animationDuration: number; // saniye cinsinden
  amount: number;
  colors: string[];
  blurLevel?: number;
  backgroundOverlayColor?: string;
}

export class Background {
  public type: 'image' | 'animated'; // Arka planın türü

  public index: number;

  public image?: string;
  public bgColor?: string;
  public textColor?: string;
  public class?: string;
  public shadeOpacity?: number = 0.2;
  public animatedStyle?: BackgroundStyle;

  constructor(
    { index, type, image, bgColor, textColor, className, shadeOpacity = 0.2, animatedStyle }: {
      index: number; type: 'image' | 'animated'; image?: string; bgColor?: string; textColor?: string; className?: string; shadeOpacity?: number; animatedStyle?: BackgroundStyle // Animasyonlu arka plan stili
      ;
    }) {
    this.index = index;
    this.type = type;
    this.image = image;
    this.bgColor = bgColor;
    this.textColor = textColor;
    this.class = className;
    this.shadeOpacity = shadeOpacity;
    this.animatedStyle = animatedStyle;
  }
}