export enum NorthernColors {
    Rotate = -1,
    None = 0,
    Blue = 1,
    Orange = 2,
    Green = 3,
    Red = 4,
    Yellow = 5,
    Purple = 6,
    Pink = 7,
    Cyan = 8,
    White = 9
}