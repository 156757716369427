import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Background, BackgroundStyle } from './models/models';

@Injectable({
  providedIn: 'root'
})
export class BackgroundService {

  private backgroundPresets: BackgroundStyle[] = [
    {
      backgroundImage: 'linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%)',
      minParticleSize: 20,
      maxParticleSize: 40,
      animationDuration: 50,
      amount: 10,
      colors: ['#583C87', '#E45A84', '#FFACAC'],
      minScale: 0.5,
      maxScale: 1.5,
      blurLevel: 55
    },
    {
      backgroundImage: 'linear-gradient(120deg, #f093fb 0%, #f5576c 100%)',
      minParticleSize: 15,
      maxParticleSize: 30,
      animationDuration: 60,
      amount: 8,
      colors: ['#ff9a9e', '#fad0c4', '#fad0c4'],
      minScale: 0.5,
      maxScale: 1.5,
      blurLevel: 55
    },
    {
      backgroundImage: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)',
      minParticleSize: 25,
      maxParticleSize: 50,
      animationDuration: 40,
      amount: 12,
      colors: ['#a18cd1', '#fbc2eb', '#fbc2eb'],
      minScale: 0.5,
      maxScale: 1.5,
      blurLevel: 55
    },
    {
      backgroundImage: 'linear-gradient(120deg, #f6d365 0%, #fda085 100%)',
      minParticleSize: 18,
      maxParticleSize: 35,
      animationDuration: 55,
      amount: 9,
      colors: ['#fbc2eb', '#a6c1ee', '#fbc2eb'],
      minScale: 0.5,
      maxScale: 1.5,
      blurLevel: 55
    },
    {
      backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
      minParticleSize: 22,
      maxParticleSize: 45,
      animationDuration: 48,
      amount: 11,
      colors: ['#a1c4fd', '#c2e9fb', '#cfd9df'],
      minScale: 0.5,
      maxScale: 1.5,
      blurLevel: 55
    },
    {
      backgroundImage: 'linear-gradient(120deg, #cfd9df 0%, #e2ebf0 100%)',
      minParticleSize: 19,
      maxParticleSize: 38,
      animationDuration: 52,
      amount: 10,
      colors: ['#d4fc79', '#96e6a1', '#d4fc79'],
      minScale: 0.5,
      maxScale: 1.5,
      blurLevel: 55
    },
    {
      backgroundImage: 'linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)',
      minParticleSize: 21,
      maxParticleSize: 42,
      animationDuration: 47,
      amount: 12,
      colors: ['#ffafbd', '#ffc3a0', '#ffafbd'],
      minScale: 0.5,
      maxScale: 1.5,
      blurLevel: 55
    },
    {
      backgroundImage: 'linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%)',
      minParticleSize: 18,
      maxParticleSize: 36,
      animationDuration: 58,
      amount: 9,
      colors: ['#a8edea', '#fed6e3', '#a8edea'],
      minScale: 0.5,
      maxScale: 1.5,
      blurLevel: 55
    },
    {
      backgroundImage: 'linear-gradient(120deg, #ffafbd 0%, #ffc3a0 100%)',
      minParticleSize: 22,
      maxParticleSize: 44,
      animationDuration: 49,
      amount: 11,
      colors: ['#84fab0', '#8fd3f4', '#84fab0'],
      minScale: 0.5,
      maxScale: 1.5,
      blurLevel: 55
    },
    {
      backgroundImage: 'linear-gradient(120deg, #a8edea 0%, #fed6e3 100%)',
      minParticleSize: 19,
      maxParticleSize: 39,
      animationDuration: 53,
      amount: 10,
      colors: ['#f6d365', '#fda085', '#f6d365'],
      minScale: 0.5,
      maxScale: 1.5,
      blurLevel: 55
    },
    {
      backgroundImage: 'linear-gradient(120deg, #02ccfe 0%, #4edbfe 100%)',
      minParticleSize: 20,
      maxParticleSize: 60,
      animationDuration: 30,
      amount: 8,
      colors: ['#a0d9efc5', '#62c1e5c5', '#20a7dbc5', '#1c96c5c5', '#cfecf7c5', '#00ecffc5'],
      minScale: 1,
      maxScale: 1.5,
      blurLevel: 100,
      backgroundOverlayColor: 'rgba(255, 255, 255, 0.2)'
    }
  ];

  private backgrounds: Background[] = [
    new Background({ index: 1, type: 'image', image: 'assets/images/background/header/page-header-bg1.png', bgColor: '#38313d', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 2, type: 'image', image: 'assets/images/background/header/page-header-bg2.png', bgColor: '#ffffff', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 3, type: 'image', image: 'assets/images/background/header/page-header-bg3.png', bgColor: '#0f1224', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 4, type: 'image', image: 'assets/images/background/header/page-header-bg4.png', bgColor: '#2f1813', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 5, type: 'image', image: 'assets/images/background/header/page-header-bg6.png', bgColor: '#050000', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 6, type: 'image', image: 'assets/images/background/header/page-header-bg7.png', bgColor: '#ffffff', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 7, type: 'image', image: 'assets/images/background/header/page-header-bg8.png', bgColor: '#121620', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 8, type: 'image', image: 'assets/images/background/header/page-header-bg9.png', bgColor: '#b1aba3', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 9, type: 'image', image: 'assets/images/background/header/page-header-bg10.png', bgColor: '#ffffff', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 10, type: 'image', image: 'assets/images/background/header/page-header-bg12.png', bgColor: '#ffffff', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 11, type: 'image', image: 'assets/images/background/header/page-header-bg13.png', bgColor: '#ffffff', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 12, type: 'image', image: 'assets/images/background/header/page-header-bg14.png', bgColor: '#916a95', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 13, type: 'image', image: 'assets/images/background/header/page-header-bg15.png', bgColor: '#ffffff', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 14, type: 'image', image: 'assets/images/background/header/page-header-bg16.png', bgColor: '#ffffff', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 15, type: 'image', image: 'assets/images/background/header/page-header-bg19.png', bgColor: '#433b5c', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 16, type: 'image', image: 'assets/images/background/header/page-header-bg20.png', bgColor: '#22516b', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 17, type: 'image', image: 'assets/images/background/header/page-header-bg21.png', bgColor: '#384a54', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 18, type: 'image', image: 'assets/images/background/header/page-header-bg22.png', bgColor: '#0e202b', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 19, type: 'image', image: 'assets/images/background/header/page-header-bg23.png', bgColor: '#232c2f', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 20, type: 'image', image: 'assets/images/background/header/page-header-bg24.png', bgColor: '#347a7a', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 21, type: 'image', image: 'assets/images/background/header/page-header-bg25.png', bgColor: '#202c2e', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 22, type: 'image', image: 'assets/images/background/header/page-header-bg26.png', bgColor: '#ffffff', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 23, type: 'image', image: 'assets/images/background/header/page-header-bg27.png', bgColor: '#000000', textColor: '#ffffff', className: 'lg:bg-contain' }),
    new Background({ index: 24, type: 'image', image: 'assets/images/background/header/page-header-bg28.png', bgColor: '#ffffff', textColor: '#ffffff', className: 'lg:bg-contain' }),

    new Background({ index: 25, type: 'image', image: 'assets/images/background/header/page-header-full-bg01.jpg', bgColor: '#1a272a', textColor: '#ffffff', className: 'bg-cover' }),
    new Background({ index: 26, type: 'image', image: 'assets/images/background/header/page-header-full-bg02.jpg', bgColor: '#1a272a', textColor: '#ffffff', className: 'bg-cover' }),
    new Background({ index: 27, type: 'image', image: 'assets/images/background/header/page-header-full-bg03.jpg', bgColor: '#1a272a', textColor: '#ffffff', className: 'bg-cover' }),
    new Background({ index: 28, type: 'image', image: 'assets/images/background/header/page-header-full-bg04.jpg', bgColor: '#1a272a', textColor: '#ffffff', className: 'bg-cover', shadeOpacity: 0.38 }),
    new Background({ index: 29, type: 'image', image: 'assets/images/background/header/page-header-full-bg05.jpg', bgColor: '#1a272a', textColor: '#ffffff', className: 'bg-cover' }),
    new Background({ index: 30, type: 'image', image: 'assets/images/background/header/page-header-full-bg06.jpg', bgColor: '#1a272a', textColor: '#ffffff', className: 'bg-cover' }),
    new Background({ index: 31, type: 'image', image: 'assets/images/background/header/page-header-full-bg07.jpg', bgColor: '#1a272a', textColor: '#ffffff', className: 'bg-cover' }),
    new Background({ index: 32, type: 'image', image: 'assets/images/background/header/page-header-full-bg08.jpg', bgColor: '#1a272a', textColor: '#ffffff', className: 'bg-cover' }),
    new Background({ index: 33, type: 'image', image: 'assets/images/background/header/page-header-full-bg09.jpg', bgColor: '#1a272a', textColor: '#ffffff', className: 'bg-cover' }),
    new Background({ index: 34, type: 'image', image: 'assets/images/background/header/page-header-full-bg10.jpg', bgColor: '#1a272a', textColor: '#ffffff', className: 'bg-cover' }),
    new Background({ index: 35, type: 'image', image: 'assets/images/background/header/page-header-full-bg11.jpg', bgColor: '#1a272a', textColor: '#ffffff', className: 'bg-cover' }),
    new Background({ index: 36, type: 'image', image: 'assets/images/background/header/page-header-full-bg12.jpg', bgColor: '#1a272a', textColor: '#ffffff', className: 'bg-cover' }),

    // Animasyonlu arka planlar
    new Background({ index: 100, type: 'animated', animatedStyle: this.backgroundPresets[0] }),
    new Background({ index: 101, type: 'animated', animatedStyle: this.backgroundPresets[1] }),
    new Background({ index: 102, type: 'animated', animatedStyle: this.backgroundPresets[2] }),
    new Background({ index: 103, type: 'animated', animatedStyle: this.backgroundPresets[3] }),
    new Background({ index: 104, type: 'animated', animatedStyle: this.backgroundPresets[4] }),
    new Background({ index: 105, type: 'animated', animatedStyle: this.backgroundPresets[5] }),
    new Background({ index: 106, type: 'animated', animatedStyle: this.backgroundPresets[6] }),
    new Background({ index: 107, type: 'animated', animatedStyle: this.backgroundPresets[7] }),
    new Background({ index: 108, type: 'animated', animatedStyle: this.backgroundPresets[8] }),
    new Background({ index: 109, type: 'animated', animatedStyle: this.backgroundPresets[9] }),

    new Background({ index: 110, type: 'animated', animatedStyle: this.backgroundPresets[10] })
  ];

  public isTransitioning: boolean = false;
  public currentBackground: BehaviorSubject<Background> = new BehaviorSubject(this.backgrounds[0]);

  constructor() {
    this.setBackground(28);
  }

  // Resim ön yükleme fonksiyonu
  preloadImage(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve();
      img.onerror = reject;
    });
  }

  public setBackground(index?: number): void {
    this.isTransitioning = true;

    let selectedBackground: Background;

    if (typeof index === 'number') {
      selectedBackground = this.backgrounds.find(bg => bg.index === index);
    } else {
      const randomIndex = Math.floor(Math.random() * this.backgrounds.length);
      selectedBackground = this.backgrounds[randomIndex];
    }

    if (selectedBackground) {
      if (selectedBackground.type === 'animated') {
        // Animasyonlu arka plan ise, doğrudan ayarla
        this.currentBackground.next(selectedBackground);
        this.isTransitioning = false;
      } else if (selectedBackground.type === 'image' && selectedBackground.image) {
        // Resimli arka plan ise, önce resmi ön yükle
        this.preloadImage(selectedBackground.image).then(() => {
          this.currentBackground.next(selectedBackground);
          this.isTransitioning = false;
        }).catch(error => {
          this.isTransitioning = false;
        });
      }
    }
  }
}