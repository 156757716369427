import {
    APP_INITIALIZER,
    CUSTOM_ELEMENTS_SCHEMA,
    Injector,
    NgModule,
} from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, NoPreloading, RouteReuseStrategy, RouterModule } from '@angular/router';

import {
    HttpClient,
    HttpClientModule,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';

import { NgxMaskModule, IConfig } from 'ngx-mask';

import { FuseMockApiModule } from '@fuse/lib/mock-api';

import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';



import { DataService } from './core/services/data.service';
import { SnackBarService } from './core/services/snack-bar.service';
import { MaterialModule } from './material.module';

import {
    TranslateLoader,
    TranslateModule,
    TranslateService,
} from '@ngx-translate/core';


import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { BrowserStorageService } from './core/services/storage/browser-storage.service';

import { LanguagesModule } from './layout/common/languages/languages.module';

import { ScreenLockerService } from './core/services/screen-locker.service';
import { SystemMenuService } from './core/services/system-menu.services';
import { registerLocaleData, TitleCasePipe } from '@angular/common';

import { FetchJsonFileService } from './core/services/fetch-json-file.services';
import { NgxEchartsModule } from 'ngx-echarts';
import { BadisOauthLoginService } from './core/services/auth/badis-oauth-login.service';
import { AppInterceptor } from './core/services/auth/guards/app-interceptor.service';
import { JwtHelperService } from './core/services/auth/jwt-helper.service';
import { ElementVisibleUserModule } from './shared/directives/element-visible-user/element-visible-user.module';

import { CreateObjectRelationsModule } from '@app/modules/create-object-relations/create-object-relations.module';

import { CreateNewTagModule } from './modules/create-new-tag/create-new-tag.module';
import { VersionStageBadgeModule } from './modules/live/components/version-stage-badge/version-stage-badge.module';
import { LoggerModule, NgxLoggerLevel, TOKEN_LOGGER_WRITER_SERVICE } from 'ngx-logger';
import { setAppInjector } from './shared/classes/app-injector';

import { environment } from '@env/environment';
import { LoggerWriterService } from './shared/services/logger-writer.service';
import {  ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { EnumService } from './core/services/enum.service';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { IEnumerable, initializeLinq } from 'linq-to-typescript';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TimeDurationsPipe } from './shared/pipe';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { ConfigService } from './core/services/config.service';
import { SharedModule } from './shared/shared.module';
import { AngularFireModule } from '@angular/fire/compat';
import { LocaleProvider } from './shared/providers/locale-provider';
import devextremeAjax from 'devextreme/core/utils/ajax';
import { sendRequestFactory } from './ng-http-client-helper';
import { CustomReportService } from './shared/components/custom-report-editor/custom-report.service';
import localeTr from '@angular/common/locales/tr';
import localeEn from '@angular/common/locales/en'
import { NgxColorsModule } from 'ngx-colors';
import { StyleManagerService } from './core/services/style-manager-service.service';
import { CustomRouteReuseStrategy } from './shared/providers/custom-route-reuse-strategy.provider';


export function httpLoaderFactory(http: any) {
    return new MultiTranslateHttpLoader(http, [
        { prefix: 'assets/languages/common/', suffix: '.json' },
        { prefix: 'assets/languages/core/', suffix: '.json' },
        { prefix: 'assets/languages/shared/', suffix: '.json' },
    ]);
}

export function initConfig(configService: ConfigService): Function {
    return () => {
        const result = configService.loadConfig();
        return result;
    };
}


const maskConfig: Partial<IConfig> = {
    validation: false,
};

const routerConfig: ExtraOptions = {
    preloadingStrategy: NoPreloading,
    scrollPositionRestoration: 'enabled',
    paramsInheritanceStrategy: 'always',
    onSameUrlNavigation: 'reload',
    useHash: false,
    anchorScrolling: 'enabled',
    scrollOffset: [0, 100],
};

declare global {
    interface Array<T> extends IEnumerable<T> { }
    interface Uint8Array extends IEnumerable<number> { }
    interface Uint8ClampedArray extends IEnumerable<number> { }
    interface Uint16Array extends IEnumerable<number> { }
    interface Uint32Array extends IEnumerable<number> { }
    interface Int8Array extends IEnumerable<number> { }
    interface Int16Array extends IEnumerable<number> { }
    interface Int32Array extends IEnumerable<number> { }
    interface Float32Array extends IEnumerable<number> { }
    interface Float64Array extends IEnumerable<number> { }
    interface Map<K, V> extends IEnumerable<[K, V]> { }
    interface Set<T> extends IEnumerable<T> { }
    interface String extends IEnumerable<string> { }
}

  
registerLocaleData(localeTr);
registerLocaleData(localeEn);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        HttpClientModule,

        LoggerModule.forRoot({
            serverLoggingUrl: `${environment.apiv2}/client-services/client-logs`,
            level: NgxLoggerLevel.ERROR,
            serverLogLevel: NgxLoggerLevel.OFF,
            disableFileDetails: false,
            enableSourceMaps: true,
            proxiedSteps: 0,
            disableConsoleLogging: false,

        }, {
            writerProvider: { provide: TOKEN_LOGGER_WRITER_SERVICE, useClass: LoggerWriterService }
        }
        ),

        TranslateModule.forRoot({
            defaultLanguage: 'tr',
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            },
            extend: true
        }),

        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),

        NgxMaskModule.forRoot(maskConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),
        ClipboardModule,
        CoreModule,
        LayoutModule,

        MaterialModule,
        ElementVisibleUserModule,
        LanguagesModule,
        CreateObjectRelationsModule,
        SharedModule,

        VersionStageBadgeModule,
        MatFormFieldModule,
        CreateNewTagModule,
        NgxColorsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerWhenStable:30000'
        }),


        AngularFireModule.initializeApp(environment.firebaseConfig),

        AngularFireMessagingModule,

        ToastrModule.forRoot({
            timeOut: 20000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            countDuplicates: true,
            progressBar: true
        }),
        ToastContainerModule
    ],

    providers: [
        TitleCasePipe,
        ScreenLockerService,
        TranslateService,
        SnackBarService,
        DataService,
        EnumService,
        BrowserStorageService,
        FetchJsonFileService,
        SystemMenuService,
        StyleManagerService,
        JwtHelperService,
        BadisOauthLoginService,
        CustomReportService,
        AppInterceptor,
        Title,
        TimeDurationsPipe,
        LocaleProvider,
        
        // { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
        { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [ConfigService],
            multi: true
        }
    ],

    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class AppModule {

    constructor(injector: Injector,httpClient: HttpClient) {
        devextremeAjax.inject({ sendRequest: sendRequestFactory(httpClient) });
        setAppInjector(injector);
        initializeLinq();
    }

}
