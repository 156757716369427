import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { GlobalVariablesService } from '../../../core/services/global-variables.service';
import { Guid } from '../../../core/constants/constants';
import { CustomReportEditor } from './models/custom-report-editor.model';
import { UtilityService } from '@app/modules/live/services/utility.service';

@Injectable()
export class CustomReportService {

    public customReports: CustomReportEditor.CustomReport[] = []; // Custom reports array

    constructor(
        private http: HttpClient,
        private varService: GlobalVariablesService,
        private utility: UtilityService) { }


    // Fetch user custom reports
    public getUserCustomReports(): Observable<CustomReportEditor.CustomReport[]> {
        const url = `${this.varService.apiV2Address}/reports/custom-reports`;
        return this.http.get<CustomReportEditor.CustomReport[]>(url).pipe(
            map((reports: CustomReportEditor.CustomReport[]) => {
                return reports.map((reportData) => {
                    // Yeni bir CustomReport instance'ı oluştur ve veriyi içine aktar
                    const report = Object.assign(new CustomReportEditor.CustomReport(), reportData);

                    // Eğer ikon string olarak geldiyse çözümle
                    if (report && report.icon && typeof report.icon === 'string') {
                        report.icon = this.utility.resolveIcon(report.icon);
                    }

                    return report;
                });
            })
        );
    }
    // Add a new custom report
    public addCustomReport(report: CustomReportEditor.CustomReport): Observable<CustomReportEditor.CustomReport> {
        const url = `${this.varService.apiV2Address}/reports/custom-reports`;
        const reportToSend = this.serializeReport(report.plainObject);
        return this.http.post<CustomReportEditor.CustomReport>(url, reportToSend).pipe(
            map((newReport) => {
                // Resolve the icon from string to IconProp
                if (newReport&& newReport.icon && typeof newReport.icon === 'string') {
                    newReport.icon = this.utility.resolveIcon(newReport.icon);
                }
                return newReport;
            })
        );
    }

    // Update an existing custom report
    public updateCustomReport(report: CustomReportEditor.CustomReport): Observable<CustomReportEditor.CustomReport> {
        const url = `${this.varService.apiV2Address}/reports/custom-reports/${report.id}`;
        const reportToSend = this.serializeReport(report.plainObject);
        return this.http.put<CustomReportEditor.CustomReport>(url, reportToSend).pipe(
            map((updatedReport) => {
                // Resolve the icon from string to IconProp
                if (updatedReport&&updatedReport.icon && typeof updatedReport.icon === 'string') {
                    updatedReport.icon = this.utility.resolveIcon(updatedReport.icon);
                }
                return updatedReport;
            })
        );
    }

    // Delete a custom report
    public deleteCustomReport(reportId: Guid): Observable<CustomReportEditor.CustomReport> {
        const url = `${this.varService.apiV2Address}/reports/custom-reports/${reportId}`;
        return this.http.delete<CustomReportEditor.CustomReport>(url);
    }

    // Helper method to serialize the report before sending to the server
    private serializeReport(report: CustomReportEditor.CustomReport | any): any {
        return {
            ...report,
            icon: report.icon ? JSON.stringify(report.icon) : null // Convert IconProp to JSON string
        };
    }
}