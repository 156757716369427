import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, AfterViewInit } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { Fireworks } from 'fireworks-js';

@Component({
  selector: 'update-modal',
  templateUrl: './update-modal.component.html',
  styleUrls: ['./update-modal.component.scss'],
  host: {
    class: 'absolute inset-0 ',
    style: 'z-index: 999999999999 !important'
  }
})
export class UpdateModalComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() countdownSeconds = 10;
  @Output() countdownFinished = new EventEmitter<void>();

  countdown$: Observable<number>;
  countdownSubscription: Subscription;
  currentCountdown: number | null = null;
  fireworks: Fireworks;

  constructor() { }


  ngOnInit() {
    this.countdown$ = timer(0, 1000).pipe(
      take(this.countdownSeconds + 1),
      map(seconds => this.countdownSeconds - seconds)
    );

    this.countdownSubscription = this.countdown$.subscribe(countdown => {
      this.currentCountdown = countdown;
      if (countdown === 0) {
        this.countdownFinished.emit();
      }
    });
  }

  initializeFireworks(): void {
    const container = document.querySelector('.fireworks-container');
    this.fireworks = new Fireworks(container, {
      sound: {
        enabled: true,
        files: [
          '/assets/sounds/fx/explosion0.mp3',
          '/assets/sounds/fx/explosion1.mp3',
          '/assets/sounds/fx/explosion2.mp3'
        ],
        volume: {
          min: 12,
          max: 76
        }
      }
    });
    this.fireworks.start();
  }
  
  ngAfterViewInit(): void {
    this.initializeFireworks();
  }

  ngOnDestroy(): void {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
    if (this.fireworks) {
      this.fireworks.stop();
    }
  }


}