
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService } from '@app/core/services/data.service';
import { LiveDTO } from '@app/modules/live/dtos/live';
import { forkJoin, lastValueFrom, mergeMap, Observable, ReplaySubject, Subject } from 'rxjs';
import { BadisOauthLoginService } from '../auth/badis-oauth-login.service';
import { ServiceBase } from '../service-base.service';
import { SettingsDTO } from './dtos/settings-dto';
import { SettingsModel } from './dtos/settings-model';


@Injectable({
    providedIn: 'root'
})
export class SettingsService extends ServiceBase {

    public settingChangedBehaivor = new ReplaySubject<SettingsModel.Settings>();
    public settingChanged = this.settingChangedBehaivor.asObservable();

    public settings: SettingsModel.Settings[];

    constructor(
        private dataService: DataService,
        private authService: BadisOauthLoginService) {
        super();

        SettingsModel.Settings.settingChanged.subscribe((setting) => {
            if (!setting) { return; }
            this.storeSetting(setting);

            setting.changeRequest = LiveDTO.ChangeRequest.User;
            this.settingChangedBehaivor.next(setting);
        });

        SettingsModel.Settings.userSettingChanged.subscribe((userSetting) => {
            if (!userSetting) { return; }
            //resolve usersetting to setting
            const setting = this.settings.firstOrDefault(p => p.id === userSetting.settingId);
            setting.userSetting = userSetting;
            setting.changeRequest = LiveDTO.ChangeRequest.User;
            this.storeUserSetting(setting);


           
            // this.settingChangedBehaivor.next(setting);

        });
    }

    initSettings(defaultSettings: SettingsDTO.Settings[], userSettings: SettingsDTO.UserSettings[]): void {

        this.settings = defaultSettings.select(p => new SettingsModel.Settings(p)).toArray();

        userSettings.forEach((p) => {
            const setting = this.settings.firstOrDefault(q => q.id === p.settingId);
            if (setting) {
                setting.userSetting.userId = p.userId;
                setting.userSetting.setIntialValue(SettingsModel.Utils.resolveValue(p.primaryValue, setting.valueType));
            }
        });

        // subscribe olunan yerde scope'a bakıp system veya user settings kullanılabilir
        this.settings.forEach((setting) => {
            setting.changeRequest = LiveDTO.ChangeRequest.Initial;
            this.settingChangedBehaivor.next(setting);
        });


    }

    public refresh(): void {
        const serviceSubject = new Subject<void>();
        const defaultSettings = this.dataService.getAny<SettingsDTO.Settings[]>('settings/3'); // Varsayılan sistem ayarları
      
        let requests = {
          defaultSettings: defaultSettings
        };
      
        if (this.authService.userAccessToken && this.authService.userAccessToken.userId) {
          // Kullanıcı oturumu varsa, kullanıcı ayarlarını da al
          const userSettings = this.dataService.getAny<SettingsDTO.UserSettings[]>('settings/user/' + this.authService.userAccessToken.userId);
          requests['userSettings'] = userSettings;
        }
      
        serviceSubject.pipe(mergeMap(() => forkJoin(requests)))
          .subscribe((result: any): void => {
            // Eğer kullanıcı ayarları yoksa, sadece varsayılan ayarları kullan
            this.initSettings(result.defaultSettings, result.userSettings || []);
            this.readyBehavior.next(true);
            this.readyBehavior.complete();
          });
      
        serviceSubject.next();
        serviceSubject.complete();
      }

      
    // public refresh(): void {

    //     const serviceSubject = new Subject<void>();
    //     const defaultSettings = this.dataService.getAny<SettingsDTO.Settings[]>('settings/3'); //default system settings
    //     const userSettings = this.dataService.getAny<SettingsDTO.UserSettings[]>('settings/user/' + this.authService.userAccessToken.userId); //current user settings

    //     serviceSubject.pipe(mergeMap((res: any) => forkJoin({
    //         defaultSettings: defaultSettings,
    //         userSettings: userSettings
    //     })))
    //         .subscribe((result: {
    //             defaultSettings: SettingsDTO.Settings[];
    //             userSettings: SettingsDTO.UserSettings[];
    //         }): void => {

    //             this.initSettings(result.defaultSettings, result.userSettings);
    //             this.readyBehavior.next(true);
    //             this.readyBehavior.complete();
    //         });

    //     serviceSubject.next();
    //     serviceSubject.complete();
    // }

    storeUserSetting(setting: SettingsModel.Settings): void {
        if (!setting) { return; }

        const putResponse = this.dataService.put<any>('settings/user', {
            userId: this.authService.userAccessToken.userId,
            settingId: setting.id,
            primaryValue: `${setting.userSetting.primaryValue}`
        });

        const result = lastValueFrom(putResponse);
        result.then((data) => { }).catch(() => { });
        this.settingChangedBehaivor.next(setting);
    }

    storeSetting(setting: SettingsModel.Settings): void {
        if (!setting) { return; }

        const model = {
            id: setting.id,
            primaryValue: `${setting.primaryValue}`
        };

        
        const putResponse = this.dataService.put<any>('settings', model);
        const result = lastValueFrom(putResponse);
        result.then((data) => { }).catch(() => { });
        this.settingChangedBehaivor.next(setting);
    }

    propagateSetting(settingId?: string): void {
        const putResponse = this.dataService.post<any>('settings/propagate', JSON.stringify(settingId));
        const result = lastValueFrom(putResponse);
        result.then((data) => { }).catch(() => { });
    }


    public getSetting(key: string): SettingsModel.Settings {

        if (!this.settings) { return null; }

        return this.settings.where(p => p.primaryKey === key).firstOrDefault();
    }

    // public getSettingValue(key: string): any {

    //     const setting = this.getSetting(key);
    //     if (!setting) { return null; }

    //     return setting.userSetting.primaryValue;
    // }

    public getSettingValue<T>(key: string): T {

        const setting = this.getSetting(key);
        if (!setting) { return null; }

        return setting.userSetting.primaryValue as T;
    }


    public getSettingValueFromId(id: string): any {

        if (!this.settings) { return null; }

        const setting = this.settings.where(p => p.id === id).firstOrDefault();

        if (!setting) { return null; }

        return setting.primaryValue;
    }


    setSettingValue(key: string, value: boolean): void {

        const setting = this.getSetting(key);
        if (!setting) { return null; }

        setting.userSetting.primaryValue = value;
    }

    isBrowsable(key: string): boolean {

        const setting = this.getSetting(key);
        if (!setting) { return false; }

        return setting.browsable;
    }

    getRelatedItems(additionalData: SettingsModel.ReferenceTableData | String): Observable<SettingsDTO.GenericItem[]> {

        let queryParams = new HttpParams();
        const propNames = Object.getOwnPropertyNames(additionalData);
        propNames.forEach(
            (propName) => {
                const propValue = additionalData[propName];
                if (propValue) { queryParams = queryParams.append(propName, propValue); }
            }
        );

        const queryUrl: any = `helper-service/get-items?${queryParams.toString()}`;

        return this.dataService.get<SettingsDTO.GenericItem[]>(queryUrl, false);

    }
}
