export namespace Maintenance {

  export enum MaintenanceType {
    ReorganizeIndex = 0,
    RebuildIndex = 1,
  }

  export enum MaintenanceStatus {
    Started = 0,
    Completed = 1,
    InProgress = 2,
    Warning = 3,
    Error = 4,
  }

  export class FragmentationProgress {
    type: MaintenanceType;
    status: MaintenanceStatus;
    progress: number;
    totalIndex: number;
    currentIndex: number;
    currentTable: string;
    currentIndexName: string;
    message: string;
    durationSeconds: number;
    fragmentationBefore: number;
    fragmentationAfter: number;
    improvement:number;
    timestamp: Date;

    constructor(data?: Partial<FragmentationProgress>) {
      if (data) {
        Object.assign(this, data);
      }
    }
  }

  export class SqlServerMetrics {
    cpuUsagePercentage: number;
    cpuCount: number;
    totalPhysicalMemoryMB: number;
    availablePhysicalMemoryMB: number;
    sqlServerMemoryUsageMB: number;
    pendingQueries: number;
    activeConnections: number;
    timestamp: Date;

    constructor(data?: Partial<SqlServerMetrics>) {
      if (data) {
        Object.assign(this, data);
      }
    }
  }


  export class IndexInfo {
    tableName: string;
    indexName: string;
    recordCount: number;
    fragmentation: number;

    constructor(data?: Partial<IndexInfo>) {
      if (data) {
        Object.assign(this, data);
      }
    }
  }

  export class DatabaseHealthScore {
    healthScore: number;
    indexes: IndexInfo[];
    timestamp: Date;

    constructor(data?: Partial<DatabaseHealthScore>) {
      if (data) {
        Object.assign(this, data);
      }
    }
  }

  export class IndexFragmentationReport {
    index:number;
    tableName: string;
    indexName: string;
    recordCount: number;
    fragmentationBefore: number;
    fragmentationAfter: number;
    improvement:number;
    durationSeconds: number;
    operationType: string;
    timestamp: Date;
  
    constructor(data?: Partial<IndexFragmentationReport>) {
      if (data) {
        Object.assign(this, data);
      }
    }
  }


}
