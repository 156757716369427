import { EventEmitter, Inject, Injectable } from '@angular/core';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs';

// devextreme localization
import { locale, loadMessages } from 'devextreme/localization';
import trMessages from 'devextreme/localization/messages/tr.json';
import enMessages from 'devextreme/localization/messages/en.json';
import { BaseService } from '@app/core/services/base-service.service';
import { Language } from './models/languages';

import { DOCUMENT } from '@angular/common';
import { LOCALE_ID } from '@angular/core';

import * as dayjs from 'dayjs'
import 'dayjs/locale/tr' // import locale
import { ServiceName, ServiceReadyStateService } from '@app/core/services/service-ready-state.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageService extends BaseService {


    supportedLanguages: Language[] = [
        {
            code: 'en-US',
            title: 'English',
            flag: 'assets/images/flags/us.svg',
            key: 'en',
            direction: 'ltr',
        },
        {
            code: 'tr-TR',
            title: 'Türkçe',
            flag: 'assets/images/flags/tr.svg',
            key: 'tr',
            direction: 'ltr',
        }
    ];

    languageChangedAndReady: EventEmitter<Language> = new EventEmitter<Language>(); // create an EventEmitter

    browserCulture: string;
    availableLanguages: Language[];
    _selectedLanguage: Language;

    set selectedLanguage(value: Language) {
        if (!value) {
            return;
        }



        // ngx-translate localization
        this._selectedLanguage = value;
        this.translateService.use(value.key);
        this.translateService.setDefaultLang(value.key);
        localStorage.setItem('language', value.key);



        // dayjs localization
        dayjs.locale(this.translateService.currentLang) // use locale


        // devextreme localization
        locale(this.translateService.currentLang);

        switch (this.translateService.currentLang) {
            case 'tr':
                loadMessages(trMessages);
                break;
            case 'en':
            default:
                loadMessages(enMessages);
                break;
        }

        this.languageChangedAndReady.emit(value);

        this.readyStateService.setServiceReady(ServiceName.Language, true);

    }

    constructor(public translateService: TranslateService,
        private readyStateService: ServiceReadyStateService
    ) {
        super();
    }

    init(): void {

        this.browserCulture = this.getBrowserCulture();
        this.availableLanguages = this.supportedLanguages.toArray();
        const lang = localStorage.getItem('language');
        this.setLanguage(lang);
    }

    get selectedLanguage(): Language {

        return this._selectedLanguage;
    }


    setLanguage(languageCode: string): void {
        this.selectedLanguage = this.availableLanguages.firstOrDefault(x => x.code.startsWith(languageCode));
        if (this.selectedLanguage) { return; }

        this.selectedLanguage = this.availableLanguages.firstOrDefault(x => x.code.startsWith(this.browserCulture));
        if (this.selectedLanguage) { return; }

        this.selectedLanguage = this.availableLanguages.firstOrDefault(x => x.code === 'en-US');
    }

    moduleInit(service: TranslateService, name: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.log.debug('LanguageService -> moduleInit -> name', name);

            this.refresh(service);

            const langChangeSubscription = service.onDefaultLangChange.pipe(
                debounceTime(1000)
            ).subscribe({
                next: (event: LangChangeEvent) => {
                    this.log.debug('LanguageService -> moduleInit -> event', name);
                    this.refresh(service);
                },
                complete: () => {
                    resolve();
                    langChangeSubscription.unsubscribe();
                },
                error: (err) => {
                    reject(err);
                    langChangeSubscription.unsubscribe();
                }
            });
        });
    }

    refresh(service: TranslateService): void {
        const exchange = service.currentLang;
        service.currentLang = "";
        service.use(exchange);

        this.languageChangedAndReady.emit(this.selectedLanguage);
    }


    /**
     * Gets the culture of the user's browser.
     * @returns The culture code of the user's browser, or null if it cannot be determined.
     */
    getBrowserCulture(): string {
        const nav = window.navigator;
        // Support for HTML 5.1 "navigator.languages"
        if (Array.isArray(nav.languages)) {
            const foundLanguage = nav.languages.find(lang => lang && lang.length);
            if (foundLanguage) return foundLanguage;
        }

        // Support for other well-known properties in browsers
        const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];
        const foundKey = browserLanguagePropertyKeys.find(key => nav[key] && nav[key].length);
        return foundKey ? nav[foundKey] : null;
    }


}
