/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/naming-convention */
export enum ControlTypeEnum {
    TextBox = 1,
    MultiLineText = 2,
    RadioButtonList = 3,
    CheckBoxes = 4,
    DatePicker = 5,
    DateTimePicker = 6,
    DropDownList = 7,
    FileUpload = 9,
}


export enum PermitState
{
    Grant,
    Denied,
}


export enum ValueTypes {
    String = 0,
    StringSelection = 1,
    Password = 2,
    Number = 3,
    NumberSelection = 4,
    Boolean = 5,
    DateTime = 6,
    Color = 7,
    PhotoUrl = 8,
    Link = 9,
    TreeSelection = 10,
    Currency = 11,
    Guid = 12,
    Json = 13,
    Enum = 14
}

export enum TrackItemKind {
    Human = 0,
    Animal = 1,
    Object = 2,
    Assets = 3,
    Baby = 4,
    BabyMother = 5
}

export enum TileExtension {
    Png = 0,
    Jpeg = 1,
    WithoutExtension = 2
}

export enum ObjectSafetyStatus {
    Pending = 0,
    Configured = 1
}

export enum DateRange {
    Fixed,

    Today,
    Las24Hours,
    YesterDay,

    Last7Days,
    ThisWeek,
    LastWeek,

    Last30Days,
    ThisMonth,
    LastMonth,
    Last3Months,
    Last6Months,

    ThisYear,
    LastYear
}

export enum PhotoRelation {
    Building = 'Building',
    Floor = 'Floor',
    Gate = 'Gate',
    GateDirection = 'GateDirection',
    Group = 'Group',
    Reader = 'Reader',
    Tag = 'Tag',
    TrackItem = 'TrackItem',
    User = 'User',
    Zone = 'Zone',
    Settings = 'Settings'
}

export enum PhotoSize {

    Small = 's',
    Medium = 'm',
    Large = 'l',
    Original = 'o'
}

export enum PhotoView {
    Default,
    Alternative,
    Top,
    Bottom,
    Left,
    Right,
    Interior,
    Exterior
}

export enum ShareRole {
    ReadOnly,
    UserCanChange
}

export enum ReaderType {
    Area = 10,
    Access = 20,
    Mobile = 30
}

export enum Hardware {
    Standard = 0,
    CoreM1 = 2,
    MobileDevice = 3
}

export enum BeaconPropertyType {
    Status = 0,

    BatteryLevel = 1,

    Temperature = 2,

    Axis = 3,

    PowerConsumption = 4,

    Activity = 1000,

    ProximityProtection = 1007,

    Reserved = 1008,

    LowBattery = 1009,

    Detached = 1010,

    Charging = 1011,

    ButtonClick = 1012,

    ProximityFailure = 1013,

    NoSignal=404,
    
    Unknown = 255,
}

export enum ValueType {
    String = 0,
    StringSelection = 1,
    Password = 2,
    Number = 3,
    NumberSelection = 4,
    Boolean = 5,
    DateTime = 6,
    Color = 7,
    PhotoUrl = 8,
    Link = 9,
    TreeSelection = 10,
    Currency = 11,
    Guid = 12
}


export enum DataDisplayMode {
    Normal = 0,
    Sensitive = 1
}

export enum DataStoreMode {
    Normal = 0,
    PublicEncrypted = 1,
    PrivateEncrypted = 2,
}

export enum DataTypes {
    Categories = 1,
    Brands = 2,
    BrandModels = 3,
    Classifications = 4,
    Department = 5,
    Others = 9,
}

export enum NumberSelection {
    Gender = 1,
    BloodType = 2,
    Condition = 3,
}

export enum Gender {
    Male = 1,
    Femal = 2,
    Others = 9,
}

export enum BloodType {
    'A+' = 1,
    'A-' = 2,
    'B+' = 3,
    'B-' = 4,
    'AB+' = 5,
    'AB-' = 6,
    'O+' = 7,
    'O-' = 8,
}

export enum RecordState {
    Active = 1,
    Deleted = 2,
    Passive = 3
}

export enum TagPriority {
    Default = 0,
    Backup = 1,
}

export enum TagKind {
    Beacon,
    Barcode,
    Rfid,
}

export enum ViewState {
    LIST = 'LIST',
    CARD = 'CARD',
}

export enum Permission {
    Grant,
    Denied,
}

export enum Alert {
    None,
    Danger,
    Warning,
    Info,
}

export enum Comparison {
    None = -1,
    Exact,
    GreaterThan,
    LessThan,
    Contain,
    RangeIn,
    RangeOut
}

export enum TableTarget {
    None = 0,
    Cell = 1,
    Row = 2
}

export enum FontStyle {
    None = 0,
    Bold = 1,
    Italic = 2,
    Underline = 4,
    Strikeout = 8,
    Blur = 16,

    UpperCase = 32,
    LowerCase = 64,
    Titlecase = 128,
}

export enum StyleAction {
    None = 0,
    Alert = 1,
    Hide = 2
}


export enum OccurToleranceType {
    Count,
    Second,
}

export enum RuleGroupMatch {
    And, //Match All
    Or, //Match Any
}

export enum DatePeriod {
    Daily,
    Weekly,
    Monthly,
    Annual,
}

export enum NotificationTypes {
    Success,
    Error,
    Warning,
}

export enum Direction {
    In = 1,
    Out = 2,
}

export enum GroupType {
    Tag,
    TrackItem,
    Report,
    Zone
}

export enum NotificationActionType {
    None,
    Email,
    Sms,
}

export enum NotificationQueueStatus {
    Pending,
    InProgress,
    Completed,
    Failed,
    Canceled,
}

export enum ReaderKind {
    Bakelor = 0,
    Wiegand = 1,
}

export enum ReaderContentType {
    Area = 10,
    Barcode = 20,
    UHFCard = 21,
    FingerPrint = 22,
    FaceId = 23,
    Voice = 24,
}

export enum StocktakingStatus {
    Started,
    Completed,
    Canceled,
}

export enum StocktakingItemStatus {
    Found,
    Missing,
    Misplaced,
}

export enum Mobility {
    Mobile,
    Immobile,
}

export enum Role {
    Admin = 1,
    User = 0,
    HR = 2,
    //etc
}

export enum CssLevel {
    None = 0,
    Low = 8,
    Medium = 30,
    High = 50,
    VeryHigh = 70,
}

export enum WiegandReaderType {
    Barcode = 20,
    UHFCard = 21,
    FingerPrint = 22,
    FaceId = 23,
    Voice = 24,
}

export enum ReadersIcon {
    //  'signal-stream' = 0,
    'router' = 1,
    'signal-stream' = 10,
    'barcode-read' = 20,
    'id-card-alt' = 21,
    'fingerprint' = 22,
    'podcast' = 23,
    'microphone' = 24,
}
export enum MonthList {
    January = 1,
    February = 2,
    March = 3,
    April = 4,
    May = 5,
    June = 6,
    July = 7,
    August = 8,
    September = 9,
    October = 10,
    November = 11,
    December = 12
}
