
import { Injectable } from '@angular/core';
import { FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { BadisOauthLoginService } from './auth/badis-oauth-login.service';
import { CustomEventService } from './custom-event.service';
import { DataService } from './data.service';
import { GlobalVariablesService } from './global-variables.service';
import { Observable } from 'rxjs';
import { CustomReportService } from '../../shared/components/custom-report-editor/custom-report.service'; // Add this import
import { ServiceName, ServiceReadyStateService } from './service-ready-state.service';
import { CustomReportEditor } from '@app/shared/components/custom-report-editor/models/custom-report-editor.model';
import { StyleManagerService } from './style-manager-service.service';
import { Guid } from '../constants/constants';
import { UtilityService } from '@app/modules/live/services/utility.service';
import { FuseLoadingService } from '@fuse/services/loading';

@Injectable()
export class SystemMenuService {

    private navComponent;
    private dynamicUserMenuClaims: any;
    private newNavigation: FuseNavigationItem[];
    public customReports: CustomReportEditor.CustomReport[] = []; // Custom reports array

    constructor(
        private _fuseNavigationService: FuseNavigationService,
        private _dataService: DataService,
        private _badisOauthLoginService: BadisOauthLoginService,
        private customEventService: CustomEventService,
        private varService: GlobalVariablesService,
        private customReportService: CustomReportService,
        private readyStateService: ServiceReadyStateService,
        private styleManager: StyleManagerService,
        private utility: UtilityService,
        private loadingService: FuseLoadingService) {
        this.customEventService.on('object-types-updated').subscribe(() => this.reloadSystemMenu());
    }

    public populateSystemMenu(): void {

        const newSystemMenu: any = this.varService.navizardSystemMenu;

        if (newSystemMenu === null || newSystemMenu === undefined) { return; }

        this.navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        if (!this.navComponent) {
            return;
        }

        this.newNavigation = newSystemMenu;
        this.navComponent.navigation = this.newNavigation;

        this.dynamicUserMenuClaims = this._badisOauthLoginService.userAccessToken.dynamicUserMenuClaims;

        this.reloadSystemMenu();

        this.newNavigation.forEach((menuItem: any) => {
            menuItem.hidden = menuItem.hide || (menuItem.roles && menuItem.roles.length > 0 &&
                !this._badisOauthLoginService.isUserInRoles(menuItem.roles) &&
                !this.userHasNoDynaminMenuClaims(menuItem)
            );

            // also apply for child items
            if (menuItem.children) {
                menuItem.children.forEach((childItem: any) => {
                    const isInRoles = !this._badisOauthLoginService.isUserInRoles(childItem.roles);
                    const isInClaims = !this.userHasNoDynaminMenuClaims(childItem.id);
                    childItem.hidden = childItem.hide || (childItem.roles && childItem.roles.length > 0 &&
                        isInRoles &&
                        isInClaims
                    );
                });
            }
        });

        this.navComponent.refresh();

        this.readyStateService.setServiceReady(ServiceName.Menu, true);
    }

    protected reloadSystemMenu(): void {
        const customObjectTypeMenu: FuseNavigationItem = this._fuseNavigationService.getItem('definitions-tracking-item-types', this.navComponent.navigation);

        if (customObjectTypeMenu) {
            this.getCustomObjectTypeMenu().subscribe((response): void => {
                customObjectTypeMenu.children = response.map((menu) => {
                    let iconWithNameSpace = 'fal:times-hexagon';

                    try {
                        if (menu?.icon) {
                            const iconConfig = `${menu.icon}`.replace(/'/g, '"');

                            if (iconConfig) {
                                const icon = JSON.parse(iconConfig);

                                if (icon) { iconWithNameSpace = `${icon[0]}:${icon[1]}`; }
                            }
                        }
                    }
                    catch { }

                    return {
                        id: menu.id,
                        title: menu.name,
                        link: `object-item-tracking/${menu.id}`,
                        icon: iconWithNameSpace,
                        hidden: !this.userHasNoDynaminMenuClaims(menu.id),
                        badge: {
                            title: menu.objectsCount,
                            classes: 'px-2 bg-gray-600 text-white rounded-full'
                        },
                        type: 'basic'
                    };
                });


            });
        }

        this.refreshCustomReportsMenu();
    }

    private getCustomObjectTypeMenu(): any {
        return this._dataService.getAny<any>('track-item-type/active');
    }

    private userHasNoDynaminMenuClaims(id: string): boolean {
        return this.dynamicUserMenuClaims?.includes(id);
    }

    //get custom report from id
    public getCustomReportById(id: string): CustomReportEditor.CustomReport {
        return this.customReports.find((report) => report.id.toString() === id);
    }

    // Refactored metod: Kullanıcıya özel raporları çekmek için
    private getUserCustomReports(): Observable<CustomReportEditor.CustomReport[]> {
        return this.customReportService.getUserCustomReports();
    }

    // Custom reports menu refresh function
    public refreshCustomReportsMenu(): void {
        this.loadingService.setAutoMode(false);
        this.getUserCustomReports().subscribe((reports: CustomReportEditor.CustomReport[]): void => {
            this.customReports = reports; // Store reports in customReports array
            const reportsMenu = this._fuseNavigationService.getItem('reports', this.navComponent.navigation);

            if (reportsMenu) {
                // Önceki raporları kaldır
                reportsMenu.children = reportsMenu.children?.filter(child => child.id !== 'custom-reports') || [];

                // Custom Reports Menü Öğesi Oluştur
                const customReportsMenu: FuseNavigationItem = {
                    id: 'custom-reports',
                    title: 'SystemAdmin.Menu.Reports.CustomReports.Title',
                    icon: 'fal:file-chart-column',
                    type: 'collapsable',
                    classes: { wrapper: 'text-yellow-500' },
                    badge: {
                        title: 'Beta',
                        "classes": "inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-500 ring-1 ring-inset ring-red-400/20"
                    },
                    children: reports.length > 0
                        ? reports.sort((a, b) => a.order - b.order).map((report) => this.generateCustomReportMenuItem(report))
                        : [] // Eğer rapor yoksa boş bir liste
                };

                if (!reportsMenu.children) {
                    reportsMenu.children = [];
                }

                reportsMenu.children.push(customReportsMenu);
                this.navComponent.refresh();
            }
        }, (error) => { }, () => {
            this.loadingService.setAutoMode(true);
        });
    }

    
    public getMenuItemById(id: string | Guid): FuseNavigationItem | undefined {
        if (!this.navComponent || !this.navComponent.navigation) {
            console.error('Navigation component or navigation data is not initialized.');
            return undefined;
        }
        return this.findMenuItemById(this.navComponent.navigation, id);
    }

    private findMenuItemById(items: FuseNavigationItem[], id: string | Guid): FuseNavigationItem | undefined {
        for (const item of items) {
            if (item.id === id) {
                return item;
            }
            if (item.children) {
                const found = this.findMenuItemById(item.children, id);
                if (found) {
                    return found;
                }
            }
        }
        return undefined;
    }

    // public updateMenuItem(updatedItem: FuseNavigationItem): void {
    //     const menuItem = this.getMenuItemById(updatedItem.id);
    //     if (menuItem) {
    //         // Mevcut menü öğesinin özelliklerini güncelle
    //         Object.assign(menuItem, updatedItem);
    //         // Navigasyon bileşenini yenile
    //         this.navComponent.refresh();
    //     } else {
    //         console.error(`Menu item with id ${updatedItem.id} not found.`);
    //     }
    // }

    public refreshMenu(): void {

        this.navComponent.refresh();
    }

    sortCustomMenuItems(menuId: string) {
        const customReportsMenu = this.getMenuItemById(menuId);
        if (!customReportsMenu) {
            console.error('Custom reports menu not found.');
            return;
        }

        customReportsMenu.children = customReportsMenu.children.sort((a, b) => {
            const aOrder = a.meta?.order ?? 0;
            const bOrder = b.meta?.order ?? 0;
            return aOrder - bOrder;
        });

    }

    generateCustomReportMenuItem(report: CustomReportEditor.CustomReport): FuseNavigationItem {

        let link: string;
        switch (report.groupKey) {
            case 'Sensor':
                link = `/live-tag-properties/${report.id}`;
                break;
            case 'Location':
                link = `/live-tag-locations/${report.id}`;
                break;

            case 'SensorHistory':
                link = `/reports/tag-property/${report.id}`;
                break;
            case 'LocationHistory':
                link = `/reports/tracking/${report.id}`;
                break;
            default:
                console.error('Unknown report groupKey:', report.groupKey);
                break;
        }

        let titleColor = "text-white";

        if (report?.colorCode)
            titleColor = this.styleManager.addColorClass(report?.colorCode)


        let wrapperColor = "text-white";
        if (report?.iconColorCode)
            wrapperColor = this.styleManager.addColorClass(report?.iconColorCode);

        const icon = this.utility.flatIcon(report.icon) ?? 'fal:file-chart-column';

        const badge = report?.isPublic ? null : {
            title: '●',
            "classes": "inline-flex items-center  px-2 py-1 text-xs font-medium text-yellow-500 "
        };

        return {
            id: report.id.toString(), // Convert Guid to string
            title: report.name,
            icon: icon,
            badge: badge,
            link: link,
            exactMatch: true,
            type: 'basic',
            classes: { wrapper: wrapperColor, title: titleColor },
            meta: { order: report.order }
        };

    }

    getCustomReportMenuItem(id: Guid): CustomReportEditor.CustomReport {
        return this.customReports.find(report => report.id === id);
    }

    addCustomReportMenuItem(reportModel: CustomReportEditor.CustomReport) {
        const parent = this.getMenuItemById("custom-reports");
        if (parent) {
            const newMenuItem = this.generateCustomReportMenuItem(reportModel);
            parent.children.push(newMenuItem);
            this.sortCustomMenuItems("custom-reports");
            this.navComponent.refresh();
            this.customReports.push(reportModel);
        }
    }

    updateCustomReportMenuItem(report: CustomReportEditor.CustomReport) {
        const menuItem = this.getMenuItemById(report.id);
        if (menuItem) {
            const needSort = menuItem.meta.order != report.order;// atama yapılmadan önce sıralama değişmiş mi kontrol et

            const updatedItem = this.generateCustomReportMenuItem(report);
            Object.assign(menuItem, updatedItem);

            if (needSort) {
                this.sortCustomMenuItems("custom-reports");
            }

            this.navComponent.refresh();
        }
    }

    removeCustomReportMenuItem(id: Guid) {

        const menuItem = this.getMenuItemById(id);
        if (menuItem) {
            const parent = this.getMenuItemById("custom-reports");
            if (parent) {
                parent.children = parent.children.filter(child => child.id !== id);
                this.navComponent.refresh();
            }
        }



    }





}
