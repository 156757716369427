<div class="background-container"
[ngStyle]="{'--blur-level': currentBackground.animatedStyle?.blurLevel + 'px', '--background-overlay-color': currentBackground.animatedStyle?.backgroundOverlayColor}">

  <div *ngIf="currentBackground">
    <ng-container [ngSwitch]="currentBackground.type">
      <ng-container *ngSwitchCase="'image'">
        <!-- Resimli arka plan -->
        <div class="bg-header" [class]="currentBackground.class" [ngStyle]="{
            'background-color': currentBackground.bgColor,
            'background-image': 'url(' + currentBackground.image + ')',
            'transform': 'scale(1)',
            'transition': 'background-image 1s ease-in-out'
          }">
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'animated'">
        <!-- Animasyonlu arka plan -->


        <div class="wrapper-form" [ngStyle]="{'background-image': currentBackground.animatedStyle.backgroundImage}">
          <span class="circle" *ngFor="let circle of circles; let i = index" [ngStyle]="circleStyles[i]"></span>
        </div>

      </ng-container>
    </ng-container>
  </div>
  <!-- Opsiyonel Gölge Katmanı -->
  <div *ngIf="shade &&currentBackground.shadeOpacity" class="absolute inset-0 bg-black" [ngStyle]="{opacity:currentBackground.shadeOpacity}"></div>
</div>