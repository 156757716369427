
export class SecurityAlert {
  content: string;
  date: Date;
  photoUrl: string;
  
  locateUrl: string;
  locateUrlParams:any;

  detailsUrl: string;
  detailsUrlParams: any;
  title: string;
  alertType: AlertType;
  progress: number;
  showImage: boolean;
}
export enum AlertType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning'
}
