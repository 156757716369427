
/* eslint-disable @typescript-eslint/no-namespace */
import { LiveDTO } from '@app/modules/live/dtos/live';
import { BehaviorSubject } from 'rxjs';
import { SettingsDTO } from './settings-dto';
import { ValueTypes } from '@app/core/constants/global-enum';


export namespace SettingsModel {

    export abstract class Utils {

        public static resolveValue<T>(val: any, valueType: ValueTypes): T {
            switch (+valueType) {
                case ValueTypes.Number:
                case ValueTypes.Currency:
                    return (+val) as unknown as T; // cast number
                case ValueTypes.Boolean:
                    return (val.toLowerCase() === 'true') as unknown as T; //cast boolean
                case ValueTypes.DateTime:
                    return new Date(val) as unknown as T; //cast date
                case ValueTypes.String:
                case ValueTypes.Password:
                case ValueTypes.Color:
                case ValueTypes.PhotoUrl:
                case ValueTypes.Link:
                default:
                    return val as unknown as T; // vat ken ay du sam tayms

            }
        }
    }

    export class ReferenceTableData {
        table: string;
        idField: string;
        displayField: string;
    }


    export class UserSetting {

        settingId: string;
        userId: string;

        _primaryValue: any;
        public get primaryValue(): any {
            return this._primaryValue;
        }

        public set primaryValue(val: any) {
            let changed = false;

            if (this._primaryValue !== val) { changed = true; }

            this._primaryValue = val;

            if (changed) { Settings.userSettingChangedBehavior.next(this); }
        }

        constructor(settingId: string) {
            this.settingId = settingId;
        }
        public setIntialValue(primaryValue: any): void {
            this._primaryValue = primaryValue;
        }

        getValue<T>(): T {
            return this.primaryValue as T;
        }

    }

    export class Settings {

        public static settingChangedBehavior = new BehaviorSubject<SettingsModel.Settings>(undefined);
        public static settingChanged = this.settingChangedBehavior.asObservable();

        public static userSettingChangedBehavior = new BehaviorSubject<SettingsModel.UserSetting>(undefined);
        public static userSettingChanged = this.userSettingChangedBehavior.asObservable();

        changeRequest: LiveDTO.ChangeRequest;

        id: string;
        primaryKey: string;
        anchorId: string;
        scope: SettingsDTO.SettingScope;
        keyGroupTitle: string;
        keyGroup: string;
        title: string;
        icon: any;
        enabledIcon: any;
        disabledIcon: any;
        description: string;
        shortDescription: string;

        defaultValue: any;
        valueType: ValueTypes;
        additionalData: ReferenceTableData | string;
        displayOrder: number;
        dependentId: string;
        releaseStatus: SettingsDTO.ReleaseStatus;
        browsable: boolean;
        userSetting: UserSetting;


        constructor(dto: SettingsDTO.Settings) {
            this.id = dto.id;
            this.primaryKey = dto.primaryKey;
            this.anchorId = dto.primaryKey.replaceAll('.', '-',);
            this.scope = dto.scope;
            this.keyGroupTitle = dto.keyGroupTitle;
            this.keyGroup = dto.keyGroup;

            if (dto.icon) { this.icon = JSON.parse(dto.icon.replace(/'/g, '"')); } // replace single quotes with double quotes
            if (dto.enabledIcon) { this.enabledIcon = JSON.parse(dto.enabledIcon.replace(/'/g, '"')); }
            if (dto.disabledIcon) { this.disabledIcon = JSON.parse(dto.disabledIcon.replace(/'/g, '"')); }

            this.title = dto.title;
            this.description = dto.description;
            this.shortDescription = dto.description.substring(0, 6);

            this.valueType = dto.valueType;
            if (dto.valueType != ValueTypes.Enum)
                this.additionalData = JSON.parse(dto.additionalData);

            this.displayOrder = dto.displayOrder;
            this.dependentId = dto.dependentId;

            this.setIntialValue(Utils.resolveValue(dto.primaryValue, this.valueType));

            this.defaultValue = Utils.resolveValue(dto.defaultValue, this.valueType);
            this.userSetting = new UserSetting(this.id);
            this.userSetting.setIntialValue(this.primaryValue);

            this.releaseStatus = dto.releaseStatus;
            this.browsable = dto.browsable;
        }

        _primaryValue: any;
        public get primaryValue(): any {
            return this._primaryValue;
        }

        public set primaryValue(val: any) {
            let changed = false;


            if (this._primaryValue !== val) { changed = true; }

            this._primaryValue = val;

            if (changed) { Settings.settingChangedBehavior.next(this); }
        }

        public setIntialValue(primaryValue: any): void {
            this._primaryValue = primaryValue;
        }

        getValue<T>(): T {
            return this.primaryValue as T;
        }

    }
}
