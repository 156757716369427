import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { AlertType, SecurityAlert, SecurityAlert as SecurityContext } from './models/security-alert';
import { ImageService } from '@app/shared/services/image.service';

@Component({
  selector: 'app-security-alert',
  templateUrl: './security-alert.component.html',
  styleUrls: ['./security-alert.component.scss']
})
export class SecurityAlertComponent extends Toast {

  safeHtml: SafeHtml;
  AlertType = AlertType;

  @Input() progress: number;

  private _alert: SecurityAlert;


  @Input()
  set alert(newAlert: SecurityAlert) {
    this._alert = newAlert;
    this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(newAlert.content); // Sanitize the content when set
  }

  get alert(): SecurityAlert {
    return this._alert;
  }
  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage, protected sanitizer: DomSanitizer,
    public imageService: ImageService) {
    super(toastrService, toastPackage);

    this.alert = JSON.parse(this.message);
  }



  dismiss() {

 
    this.toastrService.clear(this.toastPackage.toastId);
  }


}
