import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, of, throwError, timeout } from 'rxjs';
import { forkJoin } from 'rxjs';
import { GlobalVariablesService } from './global-variables.service';
import { ApplicationSettings } from '@app/shared/system/application-settings';
import { SettingsService } from './settings/settings.service';
import { ServiceName, ServiceReadyStateService } from './service-ready-state.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private readyBehavior = new BehaviorSubject<boolean>(false);
  public ready$ = this.readyBehavior.asObservable();


  constructor(
    private http: HttpClient,
    private globals: GlobalVariablesService,
    private settingService: SettingsService,
    private readyStateService: ServiceReadyStateService
  ) { }

  async loadConfig() {
    try {
      const applicationSettings = this.http.get<ApplicationSettings>(`${this.globals.apiV2Address}/client-services/get-application`).toPromise();

      const menuConfigurations = this.http.get<any>(`${this.globals.apiV2Address}/client-services/get-system-menu`).toPromise();

      const result = await forkJoin({ applicationSettings, menuConfigurations }).toPromise();

      this.globals.app = result.applicationSettings;
      this.globals.app.tenantId = 'acibadem'; // Geçici çözüm
      this.globals.navizardSystemMenu = result.menuConfigurations;


      await this.waitForSettingsReady();

      this.readyBehavior.next(true);
      this.readyStateService.setServiceReady(ServiceName.Config, true);

    } catch {
      this.readyBehavior.next(false);
      this.readyStateService.setServiceReady(ServiceName.Config, false);
    }
  }

  public async waitForSettingsReady() {
    this.settingService.refresh();
    const ready = await this.settingService.isReady.toPromise();

    if (!ready) return;

    this.globals.photoServerAddress = this.settingService.getSettingValue('General.Photo.Server.Address');
    this.globals.photoServerApiAddress =`${this.globals.photoServerAddress}/api/v2`;
    this.globals.tracker.address = this.settingService.getSettingValue('General.Tracker.SignalR.Address');
    // this.globals.tracker.address ='http://10.211.55.3:8084';
    try {
      const applicationSettings = await this.http.get<ApplicationSettings>(`${this.globals.tracker.address}/client-services/get-application`, {
        withCredentials: true
      })
        .pipe(
          timeout(3000),
          catchError(this.handleError)
        )
        .toPromise();

      if (applicationSettings) {
        this.globals.tracker.version = applicationSettings.version;
        // Versiyonla ilgili işlemleri burada yapabilirsiniz
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => new Error(`An error occurred: ${error.message}`));
  }

}


